import axios from "./axiosWrapper";
const Region = {
    getCountries() {
        return axios.get('/region/get-countries', {
            headers: {
                Authorization: "Bearer " + localStorage.getItem('access-token')
            }
        });
    },
    getStates(code) {
        return axios.get(`/region/get-states?country_code=${code}`, {
            headers: {
                Authorization: "Bearer " + localStorage.getItem('access-token')
            }
        });
    },
    updateCountryVisibility(showCountry, code) {
        return axios.put('/region/update-country-visibility',
            {
                code: code,
                show_country: showCountry,
            }, {
            headers: {
                Authorization: "Bearer " + localStorage.getItem('access-token')
            }
        });
    }
}

export default Region;