import axios from './axiosWrapper';

const AdminDepartmentCategory = {
    getAdminDepartmentCategories() {
        return axios.get(`/department/department-admin-category`, {
            headers: {
                Authorization: "Bearer " + localStorage.getItem('access-token')
            }
        })
    },
    getAdminDepartmentCategorySpecialization(id) {
        return axios.get(`/department/specialization-department-admin-category/${id}`, {
            headers: {
                Authorization: "Bearer " + localStorage.getItem('access-token')
            }
        })
    },
    listSpecializationBasedAdminDepartmentCategory(id) {
        return axios.get(`/department/list-department-admin-category-specialization/${id}`, {
            headers: {
                Authorization: "Bearer " + localStorage.getItem('access-token')
            }
        })
    },
    addDepartmentAdminCategorySpecialization(data) {
        return axios.post('/department/department-admin-category-specialization', data, {
            headers: {
                Authorization: "Bearer " + localStorage.getItem('access-token')
            }
        })
    },
    getListOfSpecializations(id) {
        return axios.get(`/department/specialization/${id}`, {
            headers: {
                Authorization: "Bearer " + localStorage.getItem('access-token')
            }
        })
    },
    addAdminDepartmentCategory(data) {
        return axios.post('/department/department-admin-category', data, {
            headers: {
                Authorization: "Bearer " + localStorage.getItem('access-token')
            }
        })
    },
    updateAdminDepartmentCategory(data, id) {
        return axios.put(`/department/department-admin-category/${id}`, data, {
            headers: {
                Authorization: "Bearer " + localStorage.getItem('access-token')
            }
        })
    },
    deleteAdminDepartmentCategory(id) {
        return axios.delete(`/department/department-admin-category/${id}`, {
            headers: {
                Authorization: "Bearer " + localStorage.getItem('access-token')
            }
        })
    },
}
export default AdminDepartmentCategory;