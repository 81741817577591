import * as React from 'react';
import OutlinedInput from '@mui/material/OutlinedInput';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import { Box } from '@mui/material';
import Country from '../../components/Country';
import Interest from '../../components/Interest';
import InstitutionType from '../../components/InstitutionType';
import Degree from '../../components/Degree';
import Institution from '../../components/Institution';
import AdminDepartmentCategory from '../../components/AdminDepartmentCategory';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
};

const names = [
    'Countries',
    'Interests',
    'Institutions',
    'Institution Types',
    'Admin Department Categories',
    'Degrees'
];


export default function MultipleSelectPlaceholder() {
    const [category, setCategory] = React.useState(["Countries"]);
    const handleChange = (event) => {
        const {
            target: { value },
        } = event;
        setCategory(typeof value === 'string' ? value.split(',') : value);
    };

    return (
        <Box className='flex flex-col md:p-8'>
            <Box className="flex justify-end">
                <Select
                    className='w-[200px] md:w-[250px]'
                    displayEmpty
                    value={category}
                    onChange={handleChange}
                    size='small'
                    input={<OutlinedInput />}
                    renderValue={(selected) => {
                        return selected.join(', ');
                    }}
                    MenuProps={MenuProps}
                // inputProps={{ 'aria-label': 'Without label' }}
                >
                    {names.map((name) => (
                        <MenuItem
                            key={name}
                            value={name}
                        >
                            {name}
                        </MenuItem>
                    ))}
                </Select>

            </Box>
            <Box className="mt-2">
                {category[0] === "Countries" && <Country />}
                {category[0] === "Interests" && <Interest />}
                {category[0] === "Institution Types" && <InstitutionType />}
                {category[0] === "Degrees" && <Degree />}
                {category[0] === 'Admin Department Categories' && <AdminDepartmentCategory />}
                {category[0] === "Institutions" && <Institution />}
            </Box>
        </Box>
    );
}
