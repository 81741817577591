import { Fragment, useEffect } from "react";
import { useNavigate } from "react-router-dom";

export default function AuthProvider({ children }) {

    const navigate = useNavigate();

    useEffect(() => {
        if (localStorage.getItem("access-token")) {
            navigate("/dashboard/manage");
        } else {
            navigate("/login");
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <Fragment>
            {children}
        </Fragment>
    )
}