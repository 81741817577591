import "./Landing.css";
import logoSrc from '../../images/logo.png';
import landingSrc from '../../images/landing.jpeg';
import TextField from '@mui/material/TextField';
import { Button } from "@mui/material";
import { EmailOutlined, Key } from "@mui/icons-material";
import Login from "../../api/login";
import { useState } from "react";
import { ThreeDots } from "react-loader-spinner";
import { useNavigate } from "react-router-dom";

export default function Landing() {

    const [email, setEmail] = useState("");
    const [otpReceieved, setOtpReceieved] = useState(false);
    const [loading, setLoading] = useState(false);
    const [otp, setOtp] = useState("");
    const [error, setError] = useState(null);
    const navigate = useNavigate();

    function sendOtp() {
        setLoading(true);
        Login.sendOtp(email)
            .then(() => {
                setOtpReceieved(true);
                setLoading(false);
            }).catch((err) => {
                setLoading(false);
                setError(err?.response?.data?.message);
            });
    }

    function validateOtp() {
        setLoading(true);
        Login.validateOtp(email, Number(otp))
            .then((response) => {
                localStorage.setItem("access-token", response.data.bearerToken);
                localStorage.setItem("email", email);
                navigate("/dashboard/manage");
            }).catch((err) => {
                setLoading(false);
                setError(err?.response?.data?.message);
            });
    }

    return (
        <div className="h-screen flex flex-col justify-center items-center p-2 page gap-10 fixed right-0 left-0 top-0 bottom-0">
            <div className="absolute z-0 top-0 left-0 right-0 bottom-0">
                <img src={landingSrc} className="w-full h-full opacity-50 bg-cover" alt="background-landing" />
            </div>
            <div className="flex justify-center md:p-16 rounded-xl shadow-lg p-8 bg-slate-700 z-40">
                <div className="flex flex-col gap-10 w-80">
                    <div className="self-center flex flex-col items-center gap-3">
                        <img src={logoSrc} alt="logo" className="w-24" />
                        <div className="self-center uppercase text-xl text-white">Admin Panel</div>
                    </div>
                    <div >
                        {!otpReceieved ?
                            <form className="flex flex-col gap-4" onSubmit={(e) => {
                                e.preventDefault();
                                sendOtp(email);
                            }}>
                                <TextField id="outlined-basic" variant="standard" size="small"
                                    value={email}
                                    label="Email"
                                    inputMode="email"
                                    error={Boolean(error)}
                                    InputProps={{
                                        startAdornment: <EmailOutlined className="pr-2" />,
                                        sx: { color: 'white' }
                                    }}
                                    onChange={(e) => { setEmail(e.target.value); setError(null); }}
                                    helperText={error}
                                    autoFocus
                                />
                                <Button variant="contained" type="submit">
                                    {loading ? <ThreeDots height="25"
                                        width="40" color="white" /> : "Send OTP"}
                                </Button>
                            </form>
                            :
                            <form className="flex flex-col gap-4" onClick={(e) => {
                                e.preventDefault();
                                validateOtp();
                            }}>
                                <TextField id="outlined-basic" variant="standard" size="small"
                                    value={otp}
                                    label="OTP"
                                    inputMode="numeric"
                                    error={Boolean(error)}
                                    helperText={error}
                                    InputProps={{
                                        startAdornment: <Key className="pr-2" />,
                                        sx: { color: 'white' },
                                        maxLength: 6
                                    }}
                                    onChange={(e) => {
                                        if (e.target.value.length !== 6) { setError("Enter a valid OTP"); }
                                        setOtp(e.target.value);
                                        setError(null);
                                    }} />

                                <Button variant="contained" type="submit">
                                    {loading ? <ThreeDots height="25"
                                        width="40" color="white" /> : "Login"}
                                </Button>
                            </form>
                        }
                    </div>
                </div>
            </div>
        </div >
    )
}
