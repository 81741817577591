import { Add, ArrowBack, Delete, Edit, UploadFile } from "@mui/icons-material";
import { Box, Button, Fab, Grid, Stack, TextField, Typography } from "@mui/material";
import { useEffect, useRef, useState } from "react";
import Constant from '../api/constant';
import { useDispatch } from "react-redux";
import { show } from '../reducers/notificationSlice';
import { Grid as GridLoader } from "react-loader-spinner";
import CropperDialog from "./CropperDialog";
import Utils from '../utils/utils';
export default function Interest() {

    const dispatch = useDispatch();
    const ref = useRef();
    const [data, setData] = useState({});
    const [filteredData, setFilteredData] = useState({});
    const [action, setAction] = useState("list");
    const [dialogImg, setDialogImg] = useState("");
    const [editInterestId, setEditInterestId] = useState(0);
    const [interest, addInterest] = useState({
        name: "",
        description: "",
        img_binary: ""
    });

    function addInterestData(key, value) {
        addInterest((_i) => ({
            ..._i,
            [key]: value
        }))
    }

    useEffect(() => {
        if (action === "list") {
            Constant.getInterests()
                .then(({ data }) => {
                    setData(data);
                    setFilteredData(data);
                }).catch((err) => dispatch(show({ message: err?.response?.data?.message, open: true, severity: "error" })));
            // eslint-disable-next-line react-hooks/exhaustive-deps
        }
    }, [action]);

    useEffect(() => {
        if (editInterestId) {
            setAction(() => "edit");
        }
    }, [editInterestId]);

    function readFile(file) {
        const fr = new FileReader();

        fr.readAsDataURL(file);
        fr.onload = (d) => {
            setDialogImg(fr.result);
            ref.current.value = "";
        }

        fr.onerror = function () {
            ref.current.value = "";
            dispatch(show({ message: "Upload different image", open: true, severity: "error" }));
        };
    }

    return (
        <Box className="flex flex-col gap-4 max-h-[80vh] h-[80vh]">
            <Box className="flex md:flex-row flex-col gap-2 md:items-center md:justify-between items-center mt-2">
                <TextField size="small" placeholder={data.count >= 0 ? `Search Interests : (Total Interests : ${data.count})` : "Loading..."} className="flex-1 w-full"
                    onChange={(e) => {
                        if (e.target.value === "") { setFilteredData(data); }
                        setFilteredData((_o) => (
                            {
                                ..._o,
                                rows: _o.rows.filter((ele) => {
                                    return new RegExp(e.target.value, "ig").test(ele.name);
                                })
                            }
                        ))
                    }} />
                <Fab variant="extended" color="primary" onClick={() => setAction("add")} className="self-end">
                    <Add sx={{ mr: 1 }} />
                    Add
                </Fab>
            </Box>
            <Box className="flex-1">
                <Grid container rowGap={1}>
                    {
                        action === "list" ? filteredData?.rows ? (
                            filteredData?.rows.length > 0 ?
                                filteredData?.rows?.map((ele) => (
                                    <Grid container className=" p-4 rounded-md items-center gap-2 md:gap-0 hover:bg-zinc-600 hover:text-white hover:cursor-pointer" md={12} xs={12} key={ele.id}>
                                        <Grid item xs={12} md={1}>
                                            <img src={Utils.bufferToBase64(ele.img_binary.data)} className="w-[40px] h-[40px] rounded-full" alt={ele.name} />
                                        </Grid>
                                        <Grid item xs={12} md={1} className="font-bold text-lg">
                                            <div className="truncate" title={ele.name}>{ele.name}</div>
                                        </Grid>
                                        <Grid item xs={12} md={9} className="break-all">
                                            <span>{ele.description || ""}</span>
                                        </Grid>
                                        <Grid item xs={12} md={1} className="flex gap-2">
                                            <Fab variant="circular" color="secondary" size="small" onClick={() => {
                                                addInterest(() => ({
                                                    name: ele.name,
                                                    description: ele.description,
                                                    img_binary: Utils.bufferToBase64(ele.img_binary.data)
                                                }));
                                                setEditInterestId(() => ele.id);
                                            }}>
                                                <Edit fontSize="small" />
                                            </Fab>
                                            <Fab variant="circular" color="error" size="small" onClick={() => {
                                                Constant.deleteInterest(ele.id)
                                                    .then(() => {
                                                        setFilteredData((data) => ({
                                                            rows: data.rows.filter((_e) => _e.id !== ele.id),
                                                            count: data.rows.filter((_e) => _e.id !== ele.id).length
                                                        }))
                                                        dispatch(show({ message: "Interest deleted successfully", open: true, severity: "success" }));
                                                    }).catch((err) => dispatch(show({ message: err.response.data.message, open: true, severity: "error" })))
                                            }}>
                                                <Delete fontSize="small" />
                                            </Fab>
                                        </Grid>
                                    </Grid>))
                                : <Typography variant="h4" className="text-center text-blue-400 h-[200px] flex items-center justify-center">No interests found</Typography>) :
                            <div className="flex h-[500px] items-center justify-center w-full"><GridLoader /></div>
                            : null
                    }
                </Grid>
                {
                    action === "add" || action === "edit" ?
                        <Box className="flex gap-10 flex-col justify-center items-center">
                            <Fab variant="extended" color="warning" onClick={() => {
                                setEditInterestId(() => 0);
                                addInterest(() => ({
                                    name: "",
                                    description: "",
                                    img_binary: ""
                                }));
                                setAction(() => "list");
                            }} className="self-start">
                                <ArrowBack sx={{ mr: 1 }} />
                                Back
                            </Fab>
                            <Typography variant="h4" className="text-blue-400">
                                {action === "add" ? 'Add Interest' : "Edit Interest"}
                            </Typography>

                            {interest.img_binary && <img src={interest.img_binary} alt="preview" className="w-[80px] h-[80px] border rounded-full" />}
                            {dialogImg !== "" && <CropperDialog imgBinary={dialogImg} addImage={addInterestData} setDialogImg={setDialogImg} />}
                            <TextField label="Name" InputProps={{ sx: { width: '300px' } }} value={interest.name} onChange={(e) => {
                                addInterestData("name", e.target.value);
                            }} />
                            <TextField label="Description" InputProps={{ sx: { width: '300px' } }} value={interest.description} onChange={(e) => {
                                addInterestData("description", e.target.value);
                            }} />
                            <Button variant="outlined" className="flex items-center" onClick={() => ref.current.click()} >
                                <UploadFile /> Upload Image
                            </Button>
                            <input type="file" ref={ref} onChange={(e) => {
                                const files = e.target.files;
                                readFile(files[0]);
                            }}
                                className="hidden"
                                accept="image/png,image/jpg,image/jpeg,image/svg" />
                            {
                                action === "add" ?
                                    <Button variant="contained" onClick={() => {
                                        Constant.addInterest(interest)
                                            .then(() => {
                                                dispatch(show({ message: "Interest Added successfully", open: true, severity: "success" }));
                                            }).catch((err) => dispatch(show({ message: err.response.data.message, open: true, severity: "error" })))

                                    }}>Add Interest</Button>
                                    :
                                    <Button variant="contained" onClick={() => {
                                        Constant.updateInterest(interest, editInterestId)
                                            .then(() => {
                                                setAction("list");
                                                addInterest({
                                                    name: "",
                                                    description: "",
                                                    img_binary: ""
                                                })
                                                dispatch(show({ message: "Interest Updated successfully", open: true, severity: "success" }));
                                            }).catch((err) => dispatch(show({ message: err.response.data.message, open: true, severity: "error" })))

                                    }}>Update Interest</Button>
                            }
                        </Box> : null
                }
            </Box >
        </Box >
    )
}