import axios from "./axiosWrapper";


const Profile = {
    getInstitutions(id, offset, status) {
        return axios.get(`/profile/get-institutions?profile_type_id=${id}&offset=${offset}` + (status ? `&status=${status}` : ""), {
            headers: {
                Authorization: 'Bearer ' + localStorage.getItem('access-token'),
            }
        })
    },
    getProfileDetails(profile) {
        return axios.get(`/profile/details?profile_type_id=${profile.profile_type_id}&email=${profile.email}`, {
            headers: {
                Authorization: 'Bearer ' + localStorage.getItem('access-token'),
            }
        })
    },
    getAdminProfileDetails(email) {
        return axios.get(`/profile/admin-details?email=${email}`, {
            headers: {
                Authorization: 'Bearer ' + localStorage.getItem('access-token'),
            }
        })
    },
    getAdminList() {
        return axios.get(`/profile/list-admins`, {
            headers: {
                Authorization: 'Bearer ' + localStorage.getItem('access-token'),
            }
        })
    },
    addAdminProfile(data) {
        return axios.post(`/profile/add-admin`, data, {
            headers: {
                Authorization: 'Bearer ' + localStorage.getItem('access-token'),
            }
        })
    },
    deleteAdminProfile(id) {
        return axios.delete(`/profile/delete-admin/${id}`, {
            headers: {
                Authorization: 'Bearer ' + localStorage.getItem('access-token'),
            }
        })
    },
    updateAdminProfile(id, data) {
        return axios.put(`/profile/update-admin/${id}`, data, {
            headers: {
                Authorization: 'Bearer ' + localStorage.getItem('access-token'),
            }
        })
    },
    updateInstitutionStatus(id, status) {
        return axios.put(`/profile/update-institution/${id}`, { status }, {
            headers: {
                Authorization: 'Bearer ' + localStorage.getItem('access-token'),
            }
        })
    }
};

export default Profile;