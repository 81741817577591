import { Box, Button, Chip, Stack, TextField, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import Region from '../api/region';
import { Grid as GridLoader } from 'react-loader-spinner';
import State from "./State";
import { useDispatch } from "react-redux";
import { show } from "../reducers/notificationSlice";
export default function Country() {

    const dispatch = useDispatch();
    const [data, setData] = useState({});
    const [filteredData, setFilteredData] = useState({});
    const [countryCode, setCountryCode] = useState("");

    useEffect(() => {
        Region.getCountries()
            .then(({ data }) => {
                setData(data);
                setFilteredData(data);
            }).catch((err) => dispatch(show({ message: err?.response?.data?.message, open: true, severity: "error" })))
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    function changeVisibility(visibility, code) {
        Region.updateCountryVisibility(visibility, code)
            .then(() => {
                setData((_o) => (
                    {
                        ..._o,
                        rows: _o.rows.map((ele) => {
                            if (ele.code === code) {
                                ele.show_country = visibility;
                                return ele;
                            }
                            return ele;
                        })
                    }
                ))
            }).catch((err) => dispatch(show({ message: err.response.data.message, open: true, severity: "error" })))
    }

    return (
        <Box className="flex flex-col gap-4 max-h-[80vh] h-[80vh]">
            <TextField size="small" placeholder={filteredData.count >= 0 ? `Search Country ( Total Countries : ${filteredData.count} )` : 'Loading...'}
                className="sticky top-0 z-10" onChange={(e) => {
                    if (e.target.value === "") { setFilteredData(data); }
                    setFilteredData((_o) => (
                        {
                            ..._o,
                            rows: _o.rows.filter((ele) => {
                                return new RegExp(e.target.value, "ig").test(ele.name);
                            })
                        }
                    ))
                }} />
            {filteredData?.rows ?
                <div className="flex-1 overflow-auto">
                    <Stack spacing={1} className="text-sm">
                        {
                            filteredData?.rows?.map((ele) =>
                                <Box key={ele.code} className="p-2 hover:bg-slate-900 hover:text-white cursor-pointer flex md:flex-row md:items-center md:justify-between flex-col gap-4 md:gap-0">
                                    <Box>
                                        <Typography variant="h6" className="flex gap-2 items-center">
                                            {ele.name}&nbsp;({ele.code})
                                            <Chip label={ele.show_country ? "Active" : "Inactive"} color={ele.show_country ? "success" : "error"} size="small" />
                                        </Typography>
                                        <Typography variant="p" className="text-blue-400">Phone Code: {ele.phone_code}</Typography>

                                    </Box>
                                    <Box className="flex gap-2">
                                        <Button variant="contained" color={!ele.show_country ? "success" : "error"}
                                            onClick={() => changeVisibility(!ele.show_country, ele.code)}>{
                                                ele.show_country ? 'Inactive' : 'Active'
                                            }</Button>
                                        <Button variant="contained" color="info" onClick={() => setCountryCode(ele.code)}>Show States</Button>
                                    </Box>
                                </Box>
                            )
                        }
                        {countryCode && <State code={countryCode} setCountryCode={setCountryCode} />}
                    </Stack>
                </div>
                : <div className="flex justify-center min-h-[500px] items-center"><GridLoader /></div>
            }
        </Box>
    )
}