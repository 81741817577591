import { Box, Card, CardMedia, Chip, Dialog, DialogContent, DialogTitle, Fab, Modal, Typography } from '@mui/material';
import { RemoveRedEye, School } from '@mui/icons-material';
import { useEffect, useState } from 'react';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Button from '@mui/material/Button';
import Profile from '../api/profile';
import { useDispatch } from 'react-redux';
import Utils from '../utils/utils';
import { show } from '../reducers/notificationSlice';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { Grid as GridLoader } from 'react-loader-spinner';
import { default as ADC } from '../api/admin-department-category';

const MAP = { college: 1, university: 0 };
const STATUS = [
    { name: "Approved", value: "approved" },
    { name: "Rejected", value: "rejected" },
    { name: "Deleted", value: "deleted" },
    { name: "Pending", value: "pending" },
];
const STATUS_COLOR = {
    pending: { name: "Pending", color: "primary" },
    approved: { name: "Approved", color: "success" },
    deleted: { name: "Deleted", color: "error" },
    rejected: { name: "Rejected", color: "warning" },
};


function SelectMenu({ items, value, setValue }) {

    return (
        <FormControl variant="standard" sx={{ m: 1, minWidth: 100 }} fullWidth>
            <InputLabel id="demo-simple-select-filled-label" size='small'>Status</InputLabel>
            <Select
                labelId="demo-simple-select-filled-label"
                id="demo-simple-select-filled"
                value={value}
                size='small'
                onChange={(e) => setValue(e.target.value)}
            >
                {
                    items.map((ele) => <MenuItem value={ele.value} key={ele.value}>{ele.name}</MenuItem>)
                }
            </Select>
        </FormControl>
    );
}

export default function Institution() {
    const dispatch = useDispatch();
    const [offset, setOffset] = useState(0);
    const [selectedInstitute, setSelectedInstitute] = useState({});
    const [open, setOpen] = useState(false);
    const [data, setData] = useState({});
    const [institution, setInstitution] = useState("college");
    const [status, setStatus] = useState("all");
    const STATUS = [
        {
            name: "All",
            value: "all",
            onClick: () => setStatus("all")
        },
        {
            name: "Pending",
            value: "pending",
            onClick: () => setStatus("pending")
        },
        {
            name: "Approved",
            value: "approved",
            onClick: () => setStatus("approved")
        },
        {
            name: "Deleted",
            value: "deleted",
            onClick: () => setStatus("deleted")
        },
        {
            name: "Rejected",
            value: "rejected",
            onClick: () => setStatus("rejected")
        }
    ]

    useEffect(() => {
        Profile.getInstitutions(MAP[institution], offset, status === "all" ? "" : status)
            .then(({ data }) => setData(data))
            .catch((err) => dispatch(show({ message: err?.response?.data?.message, open: true, severity: "error" })));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [institution, status]);

    return (
        <Box className="w-[90vw] md:w-full">
            <Box className="flex justify-between md:flex-row flex-col gap-2 pb-4">
                <Box className="flex gap-2">
                    <Chip label="College" variant={institution === "college" ? "contained" : "outlined"} color='primary' className='cursor-pointer'
                        onClick={() => setInstitution("college")} />
                    <Chip label="University" variant={institution === "university" ? "contained" : "outlined"} color='secondary' className='cursor-pointer'
                        onClick={() => setInstitution("university")} />
                </Box>
                <Box className="flex gap-2">
                    {
                        STATUS.map((ele, idx) => (
                            <Chip label={ele.name} key={idx} variant={status === ele.value ? "contained" : "outlined"}
                                color='info' className='cursor-pointer'
                                size='small'
                                onClick={ele.onClick} />
                        ))
                    }
                </Box>
            </Box>
            <Box className="md:max-h-[70%] md:h-[700px] h-[600px] max-h-[600px] overflow-auto" rowGap={6} columnGap={6}>
                <Box className="flex md:gap-6 flex-col items-center md:items-start md:flex-row md:flex-wrap flex-wrap gap-2 pt-4 pb-4">
                    {
                        Object.keys(data).length > 0 ? (data?.count > 0 ?
                            data?.rows?.map((ele) => (
                                <InstitutionCard {...{ ...ele, setOpen, setSelectedInstitute }} key={ele.id} />
                            ))
                            : <Typography>No records found</Typography>
                        ) : <div className='flex justify-center mt-20'><GridLoader /></div>}
                </Box>
            </Box>
            <InstitutionDetails {...{ open, setOpen, selectedInstitute }} />
        </Box>
    );
}

function InstitutionCard({ id, name, email, profile_type_id, profile_img_binary,
    status, setOpen, setSelectedInstitute }) {

    const dispatch = useDispatch();
    const [openSpecialization, showSpecialization] = useState(false);
    const [_status, setStatus] = useState("");
    const [updatedStatus, setUpdatedStatus] = useState("");

    return (
        <Card sx={{ maxWidth: 300, width: 400, boxShadow: '1px 1px 4px grey', height: 'max-content' }} className='border'>
            {
                profile_img_binary?.data ?
                    <CardMedia
                        sx={{ height: 140 }}
                        image={Utils.bufferToBase64(profile_img_binary.data)}
                    />
                    :
                    <Box sx={{ height: 140 }} className="flex justify-center items-center">
                        <School fontSize='large' color='secondary' />
                    </Box>
            }
            <CardContent>
                <Typography gutterBottom variant="h6" component="div" className='truncate cursor-pointer' title={name}>
                    {name}
                </Typography>
                <Box className="flex justify-between items-center mt-2">
                    <Chip label={STATUS_COLOR[updatedStatus || status].name}
                        color={STATUS_COLOR[updatedStatus || status].color} size='small' />
                    <Fab size='small' color="secondary" onClick={() => {
                        setSelectedInstitute(() => ({ email: encodeURIComponent(email), profile_type_id }));
                        setOpen(() => true);

                    }}><RemoveRedEye fontSize='small' /></Fab>
                </Box>
            </CardContent>
            <CardActions className='flex justify-center'>
                <SelectMenu value={_status || status} setValue={setStatus} items={STATUS} />
                <Button variant='contained' color='warning' onClick={() => {
                    if (_status !== "") {
                        Profile.updateInstitutionStatus(id, _status)
                            .then(() => {
                                setUpdatedStatus(_status);
                                dispatch(show({ message: "Status updated successfully", open: true, severity: "success" }));
                            })
                            .catch((err) => dispatch(show({ message: err?.response?.data?.message, open: true, severity: "error" })));
                    }
                }}>Update</Button>
            </CardActions>
            <Box className="p-2">
                <Button className='w-full' variant='contained' color='info' onClick={() => showSpecialization(true)}>Show Specializations</Button>
                {openSpecialization && <InstitutionSpecializationDetails id={id} open={openSpecialization} setOpen={showSpecialization} />}
            </Box>
        </Card>
    );
}


function InstitutionDetails({ selectedInstitute, open, setOpen }) {

    const dispatch = useDispatch();
    const [data, setData] = useState({});
    useEffect(() => {
        if (selectedInstitute.email) {
            Profile.getProfileDetails(selectedInstitute)
                .then(({ data }) => {
                    setData(data);
                })
                .catch((err) => dispatch(show({ message: err?.response?.data?.message, open: true, severity: "error" })));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedInstitute.email]);

    if (!selectedInstitute.email) return (<></>)

    return (
        <Dialog open={open} onClose={() => {
            setOpen(() => false);
            setData(() => { });
        }}>
            {
                data?.id ?
                    <Box sx={{ minWidth: 400 }}>
                        <DialogTitle className='border-b flex flex-col items-center'>
                            {data.profile_img_binary?.data ? <img src={Utils.bufferToBase64(data.profile_img_binary?.data)} alt={data.name}
                                className='w-24 h-24 rounded-full border shadow-xl' /> :
                                <Box sx={{ height: 80, width: 80 }} className="flex justify-center items-center border rounded-full">
                                    <School fontSize='large' color='secondary' />
                                </Box>}
                            {data.name}
                        </DialogTitle>
                        <DialogContent>
                            <Box className="flex gap-2 flex-col p-4 m-1">
                                <Typography variant='h5' className='underline text-blue-600'>User Details</Typography>
                                <Chip label={data.role_details.name} color='secondary' size='medium' sx={{ fontSize: 16 }} className='self-start' />
                                <Typography>Email : {data.email} </Typography>
                                <Typography>Contact : {data.contact} </Typography>
                            </Box>
                            {data.university_id ?
                                <Box className="flex gap-2 flex-col p-4 m-1">
                                    <Typography variant='h5' className='underline text-blue-600'>University Details</Typography>
                                    <Typography>Name: {data.university_details.name}</Typography>
                                    <Typography>Contact: {data.university_details.contact}</Typography>
                                    <Typography>Description: {data.university_details.description}</Typography>
                                </Box> : null}
                            <Box className="flex gap-2 flex-col p-4 m-1">
                                <Typography variant='h5' className='underline text-blue-600'>About</Typography>
                                <Typography>{data.description}</Typography>
                            </Box>
                            <Box className="flex gap-2 flex-col p-4 m-1">
                                <Typography variant='h5' className='underline text-blue-600'>Address Details</Typography>
                                <Typography>Address : {data.address} </Typography>
                                <Typography>Landmark : {data.landmark || "Not specified"} </Typography>
                                <Typography>State : {data.state_details.name} </Typography>
                                <Typography>Country : {data.country_details.name} </Typography>
                            </Box>
                        </DialogContent>
                    </Box> : null
            }
        </Dialog >
    )
}

function InstitutionSpecializationDetails({ id, open, setOpen }) {

    const dispatch = useDispatch();
    const [rows, setRows] = useState([]);
    const [adminDepartmentCategory, setAdminDepartmentCategory] = useState([]);
    const [selectedAdminCategory, setSelectedAdminCategory] = useState({});
    useEffect(() => {
        ADC.getAdminDepartmentCategorySpecialization(id)
            .then(({ data }) => {
                setRows(data?.rows);
            }).catch((e) => { console.log(e); });
        ADC.getAdminDepartmentCategories()
            .then(({ data }) => {
                setAdminDepartmentCategory(data?.rows);
            }).catch((e) => { console.log(e); })
    }, []);

    return (
        <Dialog open={open} onClose={() => setOpen(false)}>
            <DialogTitle className='flex justify-center font-bold'>Specialization List</DialogTitle>
            <DialogContent>
                {
                    rows?.length > 0 ?
                        <Box className="md:w-[500px] min-w-full">
                            {
                                rows.map((ele) =>
                                    <div className='text-lg p-2 flex flex-col bg-slate-100 rounded-md my-2 border-zinc-400 border' key={ele.id}>
                                        {adminDepartmentCategory?.filter((e) => e.id === ele.department_admin_category_id)[0]?.name &&
                                            <span className='bg-green-300 p-2 rounded-lg'>{adminDepartmentCategory?.filter((e) => e.id === ele.department_admin_category_id)[0]?.name}</span>}
                                        <span>{ele?.degree_name}</span>
                                        <span>{ele?.dept_name}</span>
                                        <span>{ele?.spec_name}</span>
                                        <div className='flex flex-col self-start w-full'>
                                            <SelectMenu items={adminDepartmentCategory.map((e) => ({ name: e.name, value: e.id }))} value={selectedAdminCategory[ele.spec_id]}
                                                setValue={(adminDepartmentId) => setSelectedAdminCategory((_o) => ({ ..._o, [ele.spec_id]: adminDepartmentId }))} />
                                            <Button color='warning' variant='contained' onClick={() => {
                                                ADC.addDepartmentAdminCategorySpecialization({ department_admin_category_id: selectedAdminCategory[ele.spec_id], specialization_id: ele?.spec_id })
                                                    .then(({ data }) => {
                                                        dispatch(show({ message: "Specialization is added in Admin Category ", open: true, severity: "success" }));
                                                    })
                                                    .catch((err) => dispatch(show({ message: err.response.data.message, open: true, severity: "error" })));
                                            }}>
                                                Set Admin Category
                                            </Button>
                                        </div>
                                    </div>)
                            }
                        </Box> : <div>No records found</div>
                }
            </DialogContent>
        </Dialog>
    )
}