import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    message: "",
    open: false,
    severity: "success",
}

export const notificationSlice = createSlice({
    name: 'notification',
    initialState,
    reducers: {
        show: (state, action) => {
            return action.payload;
        },
        hide: (state) => {
            return initialState;
        },
    },
})

export const { show, hide } = notificationSlice.actions

export default notificationSlice.reducer