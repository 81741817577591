// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.page {
    /* background: linear-gradient(to right, #2980b9, #6dd5fa, #ffffff); W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
    /* background-image: url("../../images/landing.jpeg"); */
    /* background-size: cover; */
    /* background-repeat: no-repeat; */
    /* background-size: 100% 100%; */
    /* background-opacity: 0.9; */
}`, "",{"version":3,"sources":["webpack://./src/pages/landing/Landing.css"],"names":[],"mappings":"AAAA;IACI,uIAAuI;IACvI,wDAAwD;IACxD,4BAA4B;IAC5B,kCAAkC;IAClC,gCAAgC;IAChC,6BAA6B;AACjC","sourcesContent":[".page {\n    /* background: linear-gradient(to right, #2980b9, #6dd5fa, #ffffff); W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */\n    /* background-image: url(\"../../images/landing.jpeg\"); */\n    /* background-size: cover; */\n    /* background-repeat: no-repeat; */\n    /* background-size: 100% 100%; */\n    /* background-opacity: 0.9; */\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
