import axios from "./axiosWrapper";
const Constant = {
    getInterests() {
        return axios.get('/constant/interest', {
            headers: {
                Authorization: "Bearer " + localStorage.getItem('access-token')
            }
        });
    },
    getInstitutionTypes() {
        return axios.get('/constant/institution-types', {
            headers: {
                Authorization: "Bearer " + localStorage.getItem('access-token')
            }
        })
    },
    getDegrees() {
        return axios.get(`/constant/degrees`, {
            headers: {
                Authorization: "Bearer " + localStorage.getItem('access-token')
            }
        });
    },
    updateCountryVisibility(showCountry, code) {
        return axios.put('/region/update-country-visibility',
            {
                code: code,
                show_country: showCountry,
            }, {
            headers: {
                Authorization: "Bearer " + localStorage.getItem('access-token')
            }
        });
    },
    addInterest(data) {
        return axios.post(`/constant/interest`,
            data, {
            headers: {
                Authorization: "Bearer " + localStorage.getItem('access-token')
            }
        });
    },
    updateInterest(data, id) {
        return axios.put(`/constant/interest/${id}`,
            data, {
            headers: {
                Authorization: "Bearer " + localStorage.getItem('access-token')
            }
        });
    },
    deleteInterest(id) {
        return axios.delete(`/constant/interest/${id}`,
            {
                headers: {
                    Authorization: "Bearer " + localStorage.getItem('access-token')
                }
            }
        );
    },
    addInstitutionType(data) {
        return axios.post('/constant/institution-types', data, {
            headers: {
                Authorization: "Bearer " + localStorage.getItem('access-token')
            }
        })
    },
    updateInstitutionType(data, id) {
        return axios.put(`/constant/institution-types/${id}`, data, {
            headers: {
                Authorization: "Bearer " + localStorage.getItem('access-token')
            }
        })
    },
    deleteInstitutionType(id) {
        return axios.delete(`/constant/institution-types/${id}`, {
            headers: {
                Authorization: "Bearer " + localStorage.getItem('access-token')
            }
        })
    },
    addDegree(data) {
        return axios.post(`/constant/degrees`,
            data, {
            headers: {
                Authorization: "Bearer " + localStorage.getItem('access-token')
            }
        });
    },
    updateDegree(data, id) {
        return axios.put(`/constant/degrees/${id}`,
            data, {
            headers: {
                Authorization: "Bearer " + localStorage.getItem('access-token')
            }
        });
    },
    deleteDegree(id) {
        return axios.delete(`/constant/degrees/${id}`, {
            headers: {
                Authorization: "Bearer " + localStorage.getItem('access-token')
            }
        });
    },
}

export default Constant;