import "./common-css/index.css";
import React, { createContext, useState } from 'react';
import ReactDOM from 'react-dom/client';
import { RouterProvider } from "react-router-dom";
import router from "./pages/Router";
import Notification from "./components/Notification";
import { store } from './store/store'
import { Provider } from 'react-redux'

const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
  <React.StrictMode>
    <Root />
  </React.StrictMode>
);

function Root() {
  return (
    <Provider store={store}>
      <RouterProvider router={router} />
      <Notification />
    </Provider>
  )
}
