import axios from './axiosWrapper';

const Login = {
    sendOtp(email) {
        return axios.post('/auth/send-otp', {
            email: email,
        }, {
            withCredentials: true
        })
    },
    validateOtp(email, otp) {
        return axios.post('/auth/validate-otp', {
            email: email,
            otp
        }, {
            withCredentials: true
        })
    },
    logout() {
        return axios.get('/auth/logout', {
            withCredentials: true
        });
    }
}
export default Login;