import { useEffect, useState } from "react";
import Constant from '../api/constant';
import { useDispatch } from "react-redux";
import { show } from "../reducers/notificationSlice";
import { Grid as GridLoader } from 'react-loader-spinner';
import * as React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, Fab, TextField, Typography } from "@mui/material";
import { Add, Delete, Edit } from "@mui/icons-material";


const FORM_INITIAL_STATE = { id: 0, name: "" };

export default function Degree() {
    const dispatch = useDispatch();
    const [data, setData] = useState({});
    const [open, setOpen] = useState(false);
    const [formData, setFormData] = useState(FORM_INITIAL_STATE);

    function fetchList() {
        Constant.getDegrees()
            .then(({ data }) => setData(data))
            .catch((err) => dispatch(show({ message: err.response.data.message, open: true, severity: "error" })));
    }

    useEffect(() => {
        fetchList();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        data?.rows?.length > 0 ?
            <Box className="w-[90vw] md:w-full">
                <Box className="flex justify-end p-4">
                    <Fab variant="extended" color="primary" size="medium" onClick={() => {
                        setFormData(() => FORM_INITIAL_STATE);
                        setOpen(true);
                    }}>
                        <Add /> Add
                    </Fab>
                </Box>
                <TableContainer component={Paper} sx={{ maxHeight: 700 }}>
                    <Table sx={{ minWidth: 650, }} aria-label="simple table">
                        <TableHead sx={{ background: 'black', position: 'sticky', top: 0 }}>
                            <TableRow>
                                <TableCell sx={{ color: 'white', fontWeight: 'bold' }}>Name</TableCell>
                                <TableCell sx={{ color: 'white', fontWeight: 'bold' }}>Actions</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {data?.rows?.map((row) => (
                                <TableRow
                                    key={row.id}
                                    sx={{ '& td, & th': { border: 0 } }}
                                >
                                    <TableCell component="th" scope="row">
                                        {row.name}
                                    </TableCell>
                                    <TableCell sx={{ display: 'flex', gap: 2 }}>
                                        {row.is_system ?

                                            <Typography variant="body">No actions can be taken</Typography> :
                                            <>
                                                <Fab variant="circular" color="primary" size="small" onClick={() => {
                                                    setFormData((_d) => ({
                                                        id: row.id,
                                                        name: row.name,
                                                    }));
                                                    setOpen(() => true);
                                                }}>
                                                    <Edit />
                                                </Fab>
                                                <Fab variant="circular" color="error" size="small" onClick={() => {
                                                    Constant.deleteDegree(row.id)
                                                        .then(() => {
                                                            dispatch(show({ message: "Degree deleted successfully", open: true, severity: "success" }));
                                                            setData({
                                                                rows: data.rows.filter((ele) => ele.id !== row.id),
                                                                count: data.rows.filter((ele) => ele.id !== row.id).length,
                                                            });
                                                        })
                                                        .catch((err) => dispatch(show({ message: err.response.data.message, open: true, severity: "error" })));
                                                }}>
                                                    <Delete />
                                                </Fab>
                                            </>}
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                    <AddDegree {...{ open, setOpen, formData, setFormData, fetchList }} />
                </TableContainer>
            </Box> : <div className="h-[600px] justify-center flex items-center"><GridLoader /></div>
    )
}

function AddDegree({ open, setOpen, formData, setFormData, fetchList }) {

    const dispatch = useDispatch();
    return (
        <Dialog open={open} onClose={() => {
            setOpen(() => false);
            setFormData(() => FORM_INITIAL_STATE);
        }}>
            <DialogTitle className="font-bold text-xl text-center">Add Degree</DialogTitle>
            <DialogContent>
                <Box className="flex flex-col gap-10 p-10 w-[400px] max-w-full mt-10">
                    <TextField label="Name"
                        value={formData.name}
                        onChange={(e) => setFormData((_d) => ({
                            ..._d,
                            name: e.target.value
                        }))}
                    />
                </Box>
                <DialogActions>
                    {formData.id === 0 ? <Button variant="contained" onClick={() => {
                        Constant.addDegree({ name: formData.name })
                            .then(() => {
                                dispatch(show({ message: "Degree added successfully", open: true, severity: "success" }));
                                setOpen(() => false);
                                fetchList();
                            })
                            .catch((err) => dispatch(show({ message: err.response.data.message, open: true, severity: "error" })));

                    }}>Add</Button>

                        :

                        <Button variant="contained" onClick={() => {
                            Constant.updateDegree({ name: formData.name }, formData.id)
                                .then(() => {
                                    dispatch(show({ message: "Degree updated successfully", open: true, severity: "success" }));
                                    setOpen(() => false);
                                    fetchList();
                                })
                                .catch((err) => dispatch(show({ message: err.response.data.message, open: true, severity: "error" })));

                        }}>Update</Button>
                    }
                </DialogActions>
            </DialogContent>
        </Dialog>
    )
}
