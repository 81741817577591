import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { useEffect, useState } from 'react';
import { default as ADC } from '../api/admin-department-category';
import { useDispatch } from 'react-redux';
import { show } from '../reducers/notificationSlice';
import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, Fab, TextField, Typography } from '@mui/material';
import { Grid as GridLoader } from "react-loader-spinner";
import { Add, ArrowBack, Delete, Edit, RemoveRedEye } from '@mui/icons-material';

const INITIAL_FORM_DATA = { id: 0, name: "", description: "" };

export default function AdminDepartmentCategory() {
    const dispatch = useDispatch();
    const [data, setData] = useState({});
    const [open, setOpen] = useState(false);
    const [selectedCourseCategory, setCourseCategory] = useState({ id: 0, name: "" });
    const [formData, setFormData] = useState(INITIAL_FORM_DATA);

    function fetchList() {
        ADC.getAdminDepartmentCategories()
            .then(({ data }) => setData(data))
            .catch((err) => dispatch(show({ message: err?.response?.data?.message, open: true, severity: "error" })));
    }

    useEffect(() => {
        fetchList();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        selectedCourseCategory.id === 0 ?
            (data?.rows ? (
                <Box className="w-[90vw] md:w-full">
                    <Fab variant="extended" color="primary" onClick={() => {
                        setOpen(() => true);
                        setFormData(() => INITIAL_FORM_DATA);
                    }} className="float-right" sx={{ margin: 2 }}>
                        <Add sx={{ mr: 1 }} />
                        Add
                    </Fab>
                    {data?.count > 0 ?
                        <TableContainer component={Paper} sx={{ maxHeight: 700 }} >
                            <Table sx={{ minWidth: 650 }} aria-label="simple table">
                                <TableHead sx={{ background: 'black', position: 'sticky', top: 0 }}>
                                    <TableRow>
                                        <TableCell sx={{ color: 'white', fontWeight: 'bold' }}>Name</TableCell>
                                        <TableCell sx={{ color: 'white', fontWeight: 'bold' }}>Description</TableCell>
                                        <TableCell sx={{ color: 'white', fontWeight: 'bold' }}>Actions</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {data?.rows?.map((row) => (
                                        <TableRow
                                            key={row.name}
                                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                        >
                                            <TableCell component="th" scope="row">
                                                {row.name}
                                            </TableCell>
                                            <TableCell align="left">{row.description}</TableCell>
                                            <TableCell align="left">
                                                <div className='flex gap-2'>
                                                    <Fab variant="circular" color="primary" size="small" onClick={() => {
                                                        setFormData((_d) => ({
                                                            id: row.id,
                                                            name: row.name,
                                                            description: row.description
                                                        }));
                                                        setOpen(() => true);
                                                    }}>
                                                        <Edit />
                                                    </Fab>
                                                    <Fab variant="circular" color="error" size="small" onClick={() => {
                                                        ADC.deleteAdminDepartmentCategory(row.id)
                                                            .then(() => {
                                                                dispatch(show({ message: "Department Admin Category deleted successfully", open: true, severity: "success" }));
                                                                setData({
                                                                    rows: data.rows.filter((ele) => ele.id !== row.id),
                                                                    count: data.rows.filter((ele) => ele.id !== row.id).length,
                                                                });
                                                            })
                                                            .catch((err) => dispatch(show({ message: err.response.data.message, open: true, severity: "error" })));
                                                    }}>
                                                        <Delete />
                                                    </Fab>
                                                    <Fab variant="circular" color="secondary" size="small" onClick={() => {
                                                        setCourseCategory({ id: row.id, name: row.name });
                                                    }}>
                                                        <RemoveRedEye />
                                                    </Fab>
                                                </div>
                                            </TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </ TableContainer> : <Typography variant="h4" className="text-center text-blue-400 h-[200px] flex items-center justify-center">No Course Category found</Typography>}
                    <AddAdminDepartmentCategory {...{ open, setOpen, formData, setFormData, fetchList }} />
                </Box>) :
                <Box className="h-[400px] flex justify-center items-center"><GridLoader /></Box>
            ) : <ShowAdminDepartmentCourseCategory selectedCourseCategory={selectedCourseCategory} setCourseCategory={setCourseCategory} />
    )
}

function AddAdminDepartmentCategory({ open, setOpen, formData, setFormData, fetchList }) {

    const dispatch = useDispatch();
    return (
        <Dialog open={open} onClose={() => {
            setOpen(() => false);
            setFormData(() => INITIAL_FORM_DATA);
        }}>
            <DialogTitle className="font-bold text-xl text-center">{formData.id > 0 ? 'Edit Admin Department Category' : 'Add Admin Department Category'}</DialogTitle>
            <DialogContent>
                <Box className="flex flex-col gap-10 p-10 w-[400px] mt-10 max-w-full">
                    <TextField label="Name"
                        value={formData.name}
                        onChange={(e) => setFormData((_d) => ({
                            ..._d,
                            name: e.target.value
                        }))}
                    />
                    <TextField label="Description"
                        value={formData.description}
                        onChange={(e) => setFormData((_d) => ({
                            ..._d,
                            description: e.target.value
                        }))}
                    />
                </Box>
                <DialogActions>
                    {formData.id === 0 ? <Button variant="contained" onClick={() => {
                        ADC.addAdminDepartmentCategory({ name: formData.name, description: formData.description })
                            .then(() => {
                                dispatch(show({ message: "Department Admin Category added successfully", open: true, severity: "success" }));
                                setOpen(() => false);
                                fetchList();
                            })
                            .catch((err) => dispatch(show({ message: err.response.data.message, open: true, severity: "error" })));
                    }}>Add</Button>

                        :

                        <Button variant="contained" onClick={() => {
                            ADC.updateAdminDepartmentCategory({ name: formData.name, description: formData.description }, formData.id)
                                .then(() => {
                                    dispatch(show({ message: "Department Admin Category updated successfully", open: true, severity: "success" }));
                                    setOpen(() => false);
                                    fetchList();
                                })
                                .catch((err) => dispatch(show({ message: err.response.data.message, open: true, severity: "error" })));
                        }}>Update</Button>
                    }
                </DialogActions>
            </DialogContent>
        </Dialog>
    )
}


function ShowAdminDepartmentCourseCategory({ selectedCourseCategory, setCourseCategory }) {
    const dispatch = useDispatch();
    const [data, setData] = useState({});

    useEffect(() => {
        ADC.listSpecializationBasedAdminDepartmentCategory(selectedCourseCategory.id)
            .then(({ data }) => setData(data))
            .catch((err) => dispatch(show({ message: err?.response?.data?.message, open: true, severity: "error" })));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <Box className="flex flex-col">
            <Fab variant="extended" color="warning" size="medium" className='self-start cursor-pointer' onClick={() => {
                setCourseCategory({ name: "", id: 0 });
            }}>
                <ArrowBack /> Back
            </Fab>
            <Typography variant='h4' className='flex justify-center pb-4 text-blue-400'>
                <span className='font-bold mr-2 text-cyan-700'>Admin Department Category: </span>
                {selectedCourseCategory.name}
            </Typography>
            {data?.rows?.length > 0 ?
                <TableContainer component={Paper} sx={{ maxHeight: 700 }} >
                    <Table sx={{ minWidth: 650 }} aria-label="simple table">
                        <TableHead sx={{ background: 'black', position: 'sticky', top: 0 }}>
                            <TableRow>
                                <TableCell sx={{ color: 'white', fontWeight: 'bold' }}>Institution Name</TableCell>
                                <TableCell sx={{ color: 'white', fontWeight: 'bold' }}>Degree</TableCell>
                                <TableCell sx={{ color: 'white', fontWeight: 'bold' }}>Department</TableCell>
                                <TableCell sx={{ color: 'white', fontWeight: 'bold' }}>Specialization</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {data?.rows?.map((row) => (
                                <TableRow
                                    key={row.name}
                                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                >
                                    <TableCell component="th" scope="row">{row?.institution_name}</TableCell>
                                    <TableCell align="left">{row?.degree_name}</TableCell>
                                    <TableCell align="left">{row?.dept_name}</TableCell>
                                    <TableCell align="left">{row?.spec_name}</TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer> : <Typography variant='h4' className='text-red-300 flex justify-center p-10'>No data found</Typography>}
        </Box>
    )
}