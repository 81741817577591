import * as React from 'react';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import { useDispatch, useSelector } from 'react-redux';
import { hide } from '../reducers/notificationSlice';
const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

export default function Notification() {

    const notification = useSelector((state) => state.notification);
    const dispatch = useDispatch();

    const handleClose = (_, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        dispatch(hide({
            open: false,
            message: ""
        }))
    };

    return (
        <Snackbar open={notification.open} autoHideDuration={6000} onClose={handleClose}>
            <Alert onClose={handleClose} severity={notification.severity} sx={{ width: '100%' }}>
                {notification.message}
            </Alert>
        </Snackbar>
    );
}
