import {
    Box, Button, Card, CardContent, Container, Dialog, DialogActions, DialogContent,
    DialogTitle, Fab, TextField, Typography
} from "@mui/material";
import { useEffect, useRef, useState } from "react";
import Profile from '../api/profile';
import { useDispatch } from "react-redux";
import { show } from '../reducers/notificationSlice';
import { Add, Delete, Edit, Person } from "@mui/icons-material";
import CropperDialog from './CropperDialog';
import Utils from '../utils/utils';
import { Grid as GridLoader } from 'react-loader-spinner';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';

export default function ProfilePage() {

    const dispatch = useDispatch();
    const [profile, setProfile] = useState({});
    const [open, setOpen] = useState(false);
    const [adminList, setAdminList] = useState({});
    const [imgBinary, setImgBinary] = useState("");
    const [profileImage, setProfileImage] = useState({});
    const ref = useRef();
    const [formData, setFormData] = useState({
        name: "",
        email: "",
    });

    function addFormData(key, value) {
        setFormData((_d) => ({
            ..._d,
            [key]: value
        }));
    }
    useEffect(() => {
        Profile.getAdminProfileDetails(localStorage.getItem('email'))
            .then(({ data }) => {
                setProfile(data);
            })
            .catch((err) => dispatch(show({ message: err?.response?.data?.message, open: true, severity: "error" })));

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <Container maxWidth="lg" sx={{ display: "flex", flexDirection: "column", gap: 2, maxWidth: '90vw' }}>
            <Fab variant="extended" color="primary" onClick={() => {
                setOpen(true);
            }} className="self-start">
                <Add /> Add
            </Fab>
            <Card className="flex md:flex-row md:justify-between items-center p-2 md:p-5 flex-col max-w-full">
                <Box className="relative p-2">
                    {profile.profile_img_binary || profileImage.profile_img_binary ?
                        <img src={profileImage.profile_img_binary || Utils.bufferToBase64(profile.profile_img_binary.data)}
                            alt="profile" className="w-24 rounded-full" /> :
                        <Box className="border rounded-full w-24 h-24 flex justify-center items-center"><Person sx={{ fontSize: 50 }} /></Box>
                    }
                    <Edit className="absolute top-0 right-0 cursor-pointer" onClick={() => ref.current.click()} />
                </Box>
                <CardContent className="flex flex-col gap-1 md:items-start items-center">
                    <Typography variant="h5">
                        {profile.name}
                    </Typography>
                    <Typography>
                        {profile.email}
                    </Typography>
                </CardContent>
            </Card>
            <ListAdmins {...{ adminList, setAdminList }} />
            <input type="file" className="hidden" ref={ref} onChange={(e) => {
                const fr = new FileReader();
                try {
                    fr.onload = () => {
                        setImgBinary(fr.result);
                        ref.current.value = "";
                    }
                    fr.readAsDataURL(e.target.files[0]);
                } catch (e) {
                    dispatch(show({ message: "Error while uploading image", severity: "error", open: true }));
                }
            }} />
            <CropperDialog imgBinary={imgBinary} setDialogImg={setImgBinary} callback={(binary) => {
                Profile.updateAdminProfile(profile.id, { profile_img_binary: binary })
                    .then(() => {
                        setProfileImage({ profile_img_binary: binary });
                        setImgBinary("");
                    }).catch((err) => dispatch(show({ message: err?.response?.data?.message, open: true, severity: "error" })));
            }} />
            <AdminDialog open={open} setOpen={setOpen} formData={formData} addFormData={addFormData} setFormData={setFormData} />
        </Container>
    )
}

function AdminDialog({ open, setOpen, formData, addFormData, setFormData }) {
    const dispatch = useDispatch();

    return (
        <Dialog open={open} onClose={() => {
            setFormData(() => ({ name: "", email: "" }));
            setOpen(false);
        }}>
            <DialogTitle>
                <Typography className="font-bold text-center" variant="h4">Add Admin</Typography>
            </DialogTitle>
            <DialogContent>
                <Box className="flex flex-col gap-8 w-[400px] p-6 max-w-full">
                    <TextField label="Name" value={formData.name} onChange={(e) => addFormData('name', e.target.value)} />
                    <TextField label="Email" value={formData.email} onChange={(e) => addFormData('email', e.target.value)} />
                </Box>
            </DialogContent>
            <DialogActions sx={{ display: 'flex', justifyContent: 'center' }}>
                <Button variant="contained" size="large" onClick={() => {
                    Profile.addAdminProfile(formData)
                        .then(({ data }) => {
                            dispatch(show({ message: "Admin Profile added successfully", open: true, severity: "success" }))
                        }).catch((err) => dispatch(show({ message: err?.response?.data?.message, open: true, severity: "error" })));
                }}>Add</Button>
            </DialogActions>
        </Dialog>
    )
}

function ListAdmins({ adminList, setAdminList }) {

    const dispatch = useDispatch();

    useEffect(() => {
        Profile.getAdminList()
            .then(({ data }) => {
                setAdminList(data);
            }).catch((err) => dispatch(show({ message: err?.response?.data?.message, open: true, severity: "error" })));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <Box>
            <Typography variant="h4" className="flex justify-center p-4">System Admins</Typography>
            {
                <TableContainer component={Paper}>
                    <Table sx={{ minWidth: 650, maxWidth: '100%' }} aria-label="admin table">
                        <TableHead>
                            <TableRow sx={{ background: 'black' }}>
                                <TableCell sx={{ color: 'white', fontWeight: 'bold' }}>Avatar</TableCell>
                                <TableCell sx={{ color: 'white', fontWeight: 'bold' }}>Name</TableCell>
                                <TableCell sx={{ color: 'white', fontWeight: 'bold' }}>Email</TableCell>
                                <TableCell sx={{ color: 'white', fontWeight: 'bold' }}>Actions</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {Object.keys(adminList).length > 0 ? (adminList?.count > 0 ? adminList?.rows?.map((row) => (
                                <TableRow
                                    key={row.name}
                                    sx={{ '&:last-child td, &:last-child th': { border: 0 }, '&:hover td': { background: 'rgba(0,0,0,0.7)', color: 'white' } }}
                                >
                                    <TableCell scope="row">
                                        {row.profile_img_binary?.data ?
                                            <img src={row.profile_img_binary?.data && Utils.bufferToBase64(row.profile_img_binary.data)}
                                                alt="avatar" className="rounded-full border w-12 h-12" /> :
                                            <Box className="border rounded-full w-12 h-12 flex justify-center items-center"><Person sx={{ fontSize: 20 }} /></Box>}
                                    </TableCell>
                                    <TableCell>{row.name}</TableCell>
                                    <TableCell>{row.email}</TableCell>
                                    <TableCell>
                                        <Fab color="error" size="small" onClick={() => {
                                            Profile.deleteAdminProfile(row.id)
                                                .then(({ data }) => {
                                                    setAdminList((adminList) => ({
                                                        count: adminList.rows.filter((ele) => ele.id !== row.id).length,
                                                        rows: adminList.rows.filter((ele) => ele.id !== row.id),
                                                    }))
                                                    dispatch(show({ message: data?.message, open: true, severity: "success" }));
                                                })
                                                .catch((err) => dispatch(show({ message: err?.response?.data?.message, open: true, severity: "error" })));
                                        }}><Delete /></Fab>
                                    </TableCell>
                                </TableRow>
                            )) : <Typography className="flex justify-center text-red-400" variant="h5">No Admins found</Typography>) :
                                <div className="flex p-10 justify-center"><GridLoader /></div>}
                        </TableBody>
                    </Table>
                </TableContainer>
            }
        </Box>
    )
}
