import { Dialog, Stack, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import Region from "../api/region";
import { Grid } from "react-loader-spinner";
import { useDispatch } from "react-redux";
import { show } from '../reducers/notificationSlice';
export default function State({ code, setCountryCode }) {

    const [states, setStates] = useState({});
    const dispatch = useDispatch();
    function showStates(code) {
        Region.getStates(code)
            .then(({ data }) => {
                setStates(data)
            }).catch((err) => dispatch(show({ message: err.response.data.message, open: true, severity: "error" })))
    }
    useEffect(() => {
        showStates(code);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    function handleClose() {
        setCountryCode(Boolean(""));
    }

    return (
        <Dialog open={Boolean(code)} anchor="bottom" variant="temporary" onClose={handleClose}>
            <Typography variant="h5" className="text-center sticky top-0 text-red-500 p-4 bg-zinc-200 font-bold">{'Total Provinces: ' + states.count}</Typography>
            {states?.rows ? <Stack spacing={1}>
                {
                    states?.rows.length > 0 ?
                        states?.rows.map((ele) => <Typography key={ele.id} variant="h6" className="p-4 border-b w-[400px]">
                            {ele.name}{' '}({ele.code})</Typography>) :
                        <Typography variant="h5" className="text-center text-red-500 p-4 bg-zinc-200 font-bold">No States found inside this country</Typography>
                }
            </Stack> : <Grid />}
        </Dialog>
    )
}