// import * as React from 'react';
import { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";

export default function App() {
    const location = useLocation();
    const navigate = useNavigate();
    useEffect(() => {
        if (location.pathname === "/") {
            navigate("/login");
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [location]);
    return null;
}