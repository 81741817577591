import Cropper from 'cropperjs';
import { useRef } from 'react';
import { Button, Dialog, DialogTitle } from '@mui/material';


export default function CropperDialog({ imgBinary, addImage, setDialogImg, callback }) {
    const ref = useRef();
    const cropper = useRef();
    return (
        <Dialog open={Boolean(imgBinary)} onClose={() => {
            setDialogImg("");
        }}>
            <DialogTitle className='flex justify-between'>
                Crop Image
                <Button variant="contained" onClick={() => {
                    if (callback) {
                        callback(cropper.current.getCroppedCanvas().toDataURL("image/png"));
                    } else {
                        addImage("img_binary", cropper.current.getCroppedCanvas().toDataURL("image/png"));
                    }
                    setDialogImg("");
                }}>Crop</Button>
            </DialogTitle>
            <img src={imgBinary} alt="cropping" ref={ref} onLoad={() => {
                cropper.current = new Cropper(ref.current, {
                    aspectRatio: 1 / 1,
                    data: {
                        width: '100px',
                        height: '100px',
                    },
                    cropBoxResizable: false,
                });
            }} />
        </Dialog>
    )
}

